import React,{Component} from "react";
import css_classes from '../style.module.css';

export  class PageTemplate7 extends Component{

    constructor(props) {
        super();
        this.content = props.content;
        console.log(props.bg,'aaa');
        switch (props.bg){
            case 'white':
                this.wrapperClass=css_classes.template_7_white;
                break;
            default:
                this.wrapperClass='';
                break;
        }
    }
    render() {
        return <div className={css_classes.template_7_section+' '+css_classes.page+' '+this.wrapperClass} >
            <div className={'am_container '+css_classes.h100+' '+css_classes.centered_info_wrap}>
                <div className={'flex align_items_center ' + css_classes.template_7_content}>
                    <div className={css_classes.template_7_info}>
                        <div className={css_classes.title} dangerouslySetInnerHTML={{__html: this.content.title}}></div>
                        <div className={css_classes.template_7_text}
                             dangerouslySetInnerHTML={{__html: this.content.content}}></div>
                    </div>
                    <div>
                        <img src={this.content.image} className={css_classes.template_7_img}/>
                    </div>

                </div>

            </div>
        </div>
    }
}